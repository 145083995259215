import React, { Component } from 'react';
import Select from 'react-select';

class SelectApi extends Component {
  constructor(props) {
    super(props);
    let groups = window._env_.URLS.filter((url) => !url.hidden).reduce((r, a) => {
      r[a.group] = [...r[a.group] || [], a];
      return r;
     }, {});
    this.state = {
      // availableApis: window._env_.URLS.map(item => ({ value: slugify(item.name).toLowerCase(), label: item.name, url: item.url })),
      availableApis: Object.entries(groups).map(group => {
        return {
          label: group[0],
          options: group[1].map(item => ({ value: item.slug, label: item.version, url: item.url }))
        }
      })
    }
  }
  groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }), 
    {},
  );

  render() {
    return (
        <Select
            className="select__api"
            autoFocus={this.props.autoFocus}
            value={this.props.value}
            onChange={this.props.onChange}
            options={this.state.availableApis}
            noOptionsMessage={() => 'No api found'}
            placeholder={'Search Api...'}
            theme={(theme) => ({
              ...theme,
              colors: {
              ...theme.colors,
                primary: window._env_.THEME_COLOR,
              },
            })}
          />
    );
  }
}

export default SelectApi;
