import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Route, BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import RedocPage from './RedocPage/RedocPage';

ReactDOM.render((
  <Router>
    <div>
      <Switch>
        <Redirect exact from='/scsn' to={window._env_.DEFAULTS.scsn} />
        <Redirect exact from='/broker' to={window._env_.DEFAULTS.broker} />
        <Redirect exact from="/" to={window._env_.DEFAULTS.scsn} />
        <Route path="/:api+" component={RedocPage} />
      </Switch>
    </div>
  </Router>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
